<template>
  <div class="attribute-wrap">
    <header>
      <div :class="{'col-2': index === 1 }"  class="col" v-for="(col, index) in heading" :key="index">
        {{ col }}
      </div>
    </header>
    <div class="row" v-for="(item, index) in items" :key="index">
      <div v-for="(obj, sub) in Object.keys(item)" :key="obj.id" :class="{'col-2': sub === 1 }" class="col">
        {{ item[obj] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: [Object],
    collectionCartID: [String, Number],
    heading: {
      type: Array,
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      }
  },
  data() {
    return {
      quantity: 1
    }
  },
  watch: {
    quantity() {
      this.$emit('updateCart', {Price: this.details['Price'].toFixed(2), Quantity: this.quantity, id: this.details.id, total: this.getTotal})
    }
  },
  computed: {
    getTotal () {
      return this.details['Price'].toFixed(2) * this.quantity
    }
  },
  methods: {
    addProduct () {
      if (this.quantity >= 1) {
        this.quantity++
      }
    },
    removeProduct () {
      if (this.quantity > 1) {
        this.quantity--
      }
    }
  }
}
</script>

<style lang="sass" scoped>
%text-props
  @include font-source('Poppins', 13px, 400, $black1)
.attribute-wrap
    display: flex
    justify-content: flex-end
    flex-direction: column
    position: relative
    .arrow-down
      position: absolute
      width: 0
      height: 0
      top: -2px
      z-index: 10
      left: 40px
      border-left: 15px solid transparent
      border-right: 15px solid transparent
      border-top: 18px solid $white
    header
      padding: 2px 16px
      background: $grey-2
      border-bottom: 1px solid map-get($greyShades, 'greyShade4')
    .row, header
      display: flex
      padding: 1em
      min-height: 1.8em
      align-items: center
      @extend %text-props
    .row
      background: #e2f6a6
    .col
      flex: 1
      display: flex
      &.empty
        flex: 3
        @media screen and (max-width: $md-breakpoint)
          flex: 1
      &.small
        justify-content: flex-end
        padding-right: 10px
        .close-btn
          img
            width: 15px
      .quantity
          display: flex
          align-items: center
          border: 1px solid map-get($greyShades, 'greyShade4')
          padding: 0 0.5em
          span
            padding: 0.5em
            &:first-child, &:nth-child(3)
              @include font-source('Poppins', 16px, 400, $text-grey)
    .col-2
      flex: 2
</style>