import { render, staticRenderFns } from "./SaleHistory.vue?vue&type=template&id=6033cac8&scoped=true&"
import script from "./SaleHistory.vue?vue&type=script&lang=js&"
export * from "./SaleHistory.vue?vue&type=script&lang=js&"
import style0 from "./SaleHistory.vue?vue&type=style&index=0&id=6033cac8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6033cac8",
  null
  
)

export default component.exports