<template>
  <div>
    <TopSection from="Home" page="My Account" />
    <div class="center-wrapper page-wrapper">
      <div class="side-wrap">
        <TableSideBar :items="sideBarData" />
      </div>
      <div class="main-content">
        <div class="filters">
          <Input type="text" label="PO#" :value="searchInput.PO" @onEnter="searchQuery" @onUpdate="searchInput.PO = $event" />
          <Input type="number" label="Order Number" :value="searchInput.orderNumber" @onEnter="searchQuery" @onUpdate="searchInput.orderNumber = $event" />
          <Input type="date" label="Order Date" :value="searchInput.date" @onEnter="searchQuery" @onUpdate="searchInput.date = $event" />
          <div class="search-btn">
            <button @click="searchQuery">Search</button>
          </div>
        </div>
        <div class="no-result" v-if="filterOrder && filterOrder.length <= 0">
          No Result Found
        </div>
        <Table :heading="heading" :List="filterOrder" :icons="true" @active="activeBar">
          <ProductAttributes :heading="productHeadings" :items="getProductDetails" />
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopSection from '@/components/molecules/TopSection'
import Table from '@/components/molecules/Table'
import TableSideBar from '@/components/molecules/TableSideBar'
import ProductAttributes from '@/components/molecules/ProductAttributes'
import Input from '@/components/organisms/Input'
import Common from '@/mixins/Common'
export default {
  mixins: [Common],
  components: {
    TopSection,
    Table,
    TableSideBar,
    ProductAttributes,
    Input
  },
  data() {
    return {
      heading: ['No.', 'Order No', 'PO#', 'Ship Date', 'Total Cube Ft.', 'Total Price', 'Action'],
      productHeadings: ['SKU', 'Product Name', 'Cube Ft.', 'Basic Cost', 'Qty.', 'Total Cube Ft.', 'Total Price'],
      activeId: null,
      searchInput: {}
    }
  },
  metaInfo() {
    return {
      title: 'Order History Details'
    }
  },
  computed: {
    ...mapState('order', ['orderList']),
    getOrder() {
      return this.orderList.map(({ id, OrderNumber, PONumber, ShipDate, TotalCubFt, SubTotal }) => ({ id, OrderNumber, PONumber, ShipDate, TotalCubFt, SubTotal, ['action']: 'View' }))
    },
    filterOrder() {
      const orderList = this.getOrder.filter(item => {
        if (this.$route.query && this.$route.query.PO && this.$route.query.orderNumber && this.$route.query.date) {
          return item.PONumber.includes(this.$route.query.PO) || item.OrderNumber.includes(this.$route.query.orderNumber) || item.ShipDate.includes(this.$route.query.date)
        } else if (this.$route.query && this.$route.query.PO && this.$route.query.orderNumber) {
          return item.PONumber.includes(this.$route.query.PO) || item.OrderNumber.includes(this.$route.query.orderNumber)
        } else if (this.$route.query && this.$route.query.PO && this.$route.query.date) {
          return item.PONumber.includes(this.$route.query.PO) || item.ShipDate.includes(this.$route.query.date)
        } else if (this.$route.query && this.$route.query.orderNumber && this.$route.query.date) {
          return item.OrderNumber.includes(this.$route.query.orderNumber) || item.ShipDate.includes(this.$route.query.date)
        } else if (this.$route.query && this.$route.query.PO) {
          return item.PONumber.includes(this.$route.query.PO)
        } else if (this.$route.query && this.$route.query.orderNumber) {
          return item.OrderNumber.includes(this.$route.query.orderNumber)
        } else if (this.$route.query && this.$route.query.date) {
          return item.ShipDate.includes(this.$route.query.date)
        } else {
          return item
        }
      })
      return orderList
    },
    getProductDetails() {
      let productDetails = []
      const orderDetails = this.orderList.filter(item => item.id === this.activeId)
      if (orderDetails && orderDetails.length > 0) {
        productDetails = orderDetails[0].order_product.map(({ Price, Quantity, product }) => ({ Price, Quantity, product }))
        productDetails = productDetails.map(({ Price, Quantity, product }) => ({ ['SKU'] : product['SKU'], ['Name']: product['Name'], ['CubeFt']: product['CubeFt'], ['Basic_cost']: product['Price'].toFixed(2), Quantity, ['total_CubeFt']: product['CubeFt'] * Quantity,['Price']: Price * Quantity}))
      }
      return productDetails
    }
  },
  mounted() {
    if (this.$route.query && (this.$route.query.PO || this.$route.query.orderNumber || this.$route.query.date)) {
      this.searchInput = this.$route.query
    }
    this.$store.dispatch('order/fetchOrder')
  },
  methods: {
    selectWareHouse(value) {
      this.selectedWareHouse = value.name
    },
    selectCategory(value) {
      this.selectedCategoryList = value.name
    },
    selectCollection(value) {
      this.selectedCollectionList = value.name
    },
    activeBar(id) {
      this.activeId = id
    },
    searchQuery() {
      let query = {}
      if (this.searchInput.PO && this.searchInput.orderNumber && this.searchInput.date) {
        query = Object.assign({}, { PO: this.searchInput.PO, orderNumber: this.searchInput.orderNumber, date: this.searchInput.date })
      } else if (this.searchInput.PO && this.searchInput.orderNumber) {
        query = Object.assign({}, { PO: this.searchInput.PO, orderNumber: this.searchInput.orderNumber })
      } else if (this.searchInput.PO && this.searchInput.date) {
        query = Object.assign({}, { PO: this.searchInput.PO, date: this.searchInput.date })
      } else if (this.searchInput.orderNumber && this.searchInput.date) {
        query = Object.assign({}, { orderNumber: this.searchInput.orderNumber, date: this.searchInput.date })
      } else if (this.searchInput.PO) {
        query = Object.assign({}, { PO: this.searchInput.PO })
      } else if (this.searchInput.orderNumber) {
        query = Object.assign({}, { orderNumber: this.searchInput.orderNumber })
      } else if (this.searchInput.date) {
        query = Object.assign({}, { date: this.searchInput.date })
      }
      Object.keys(this.$route.query).forEach(item => {
        if (!this.searchInput[item] || this.searchInput[item] == '' || this.searchInput[item] == null || this.searchInput[item] == undefined) {
          delete query.item
        }
      })
      this.$router.replace({ query: query })
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../sass/common'
.no-result
  padding: 50px 0
</style>
